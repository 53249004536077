<template lang="pug">
  include ../plugins/pug/btn

  section#services__block.section.overflow-h
    div.container
      div.title__panel
        div.title__input
          p Services
        +btn("Read More", "services").btn__primary.title__btn
      div(
        ref="card__swiper"
        :class="{ 'swiper': isMobile }"
      ).card__swiper
        div(:class="{ 'swiper-wrapper': isMobile }").service__card
          div(
            v-for="(item, index) in cardItems"
            :key="index"
          ).swiper-slide
            div.service__card-item.card
              div.service__card-body.card__body
                div.triangle
                div.service__card-title
                  div.service__card-icon
                    img(
                      :src="item.cardIcon"
                      alt="service icon"
                    )
                  h3.h3.fw-700 {{ item.cardTitle }}
                div.service__card-text.text {{ item.cardDescription }}

        div.swiper-pagination.slider__pagination.pagination__relative
        div.swiper-button-prev.d-none
        div.swiper-button-next.d-none
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// images
import CardIcon1 from '@/assets/icons/card-icon-1.svg'
import CardIcon2 from '@/assets/icons/card-icon-2.svg'
import CardIcon3 from '@/assets/icons/card-icon-3.svg'
import CardIcon4 from '@/assets/icons/card-icon-4.svg'
import CardIcon5 from '@/assets/icons/card-icon-5.svg'
import CardIcon6 from '@/assets/icons/card-icon-6.svg'
export default {
  name: 'ServiceCard',
  data () {
    return {
      isMobile: false,
      cardItems: [
        {
          cardIcon: CardIcon1,
          cardTitle: 'Software Development',
          cardDescription: 'Unique software solutions designed for customers specific business goals.'
        },
        {
          cardIcon: CardIcon2,
          cardTitle: 'Mobile Development',
          cardDescription: 'Cutting-edge mobile app development for iOS and Android.'
        },
        {
          cardIcon: CardIcon3,
          cardTitle: 'Web Development',
          cardDescription: 'Responsive, user-friendly web applications customized for diverse client needs.'
        },
        {
          cardIcon: CardIcon4,
          cardTitle: 'UI/UX Design',
          cardDescription: 'Intuitive and engaging UI/UX design for enhanced user experience.'
        },
        {
          cardIcon: CardIcon5,
          cardTitle: 'DevOps Services',
          cardDescription: 'Streamlined services for efficient development and software management.'
        },
        {
          cardIcon: CardIcon6,
          cardTitle: 'IT Consulting and Strategy',
          cardDescription: 'Expert IT consulting for digital transformation and business growth.'
        }
      ]
    }
  },
  mounted () {
    if (document.documentElement.clientWidth <= 767) {
      this.isMobile = true
      setTimeout(() => this.swiperInit(), 300)
    }
  },
  methods: {
    swiperInit () {
      new Swiper(this.$refs.card__swiper, {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 24,
        centeredSlides: true,
        loop: true,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        }
      })
    }
  }
}
</script>
